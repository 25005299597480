var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"validator",attrs:{"slim":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('src\\views\\bookingsettings\\edit.9872')))])],1),_c('v-select',{attrs:{"label":_vm.$t('src\\views\\bookingsettings\\edit.263'),"items":_vm.tripsSelect,"item-text":"name","item-value":"_id","outlined":""},model:{value:(_vm.entity.trip),callback:function ($$v) {_vm.$set(_vm.entity, "trip", $$v)},expression:"entity.trip"}}),_c('v-select',{attrs:{"label":_vm.$t('src\\views\\bookingsettings\\edit.7326'),"items":_vm.directionTypesSelect,"item-text":"title","item-value":"value","outlined":""},model:{value:(_vm.entity.directionType),callback:function ($$v) {_vm.$set(_vm.entity, "directionType", $$v)},expression:"entity.directionType"}}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('src\\views\\bookingsettings\\edit.9664'),"outlined":"","error-messages":errors[0]},model:{value:(_vm.entity.approveCount),callback:function ($$v) {_vm.$set(_vm.entity, "approveCount", $$v)},expression:"entity.approveCount"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('src\\views\\bookingsettings\\edit.4312'),"outlined":"","error-messages":errors[0]},model:{value:(_vm.entity.approveDays),callback:function ($$v) {_vm.$set(_vm.entity, "approveDays", $$v)},expression:"entity.approveDays"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('src\\views\\bookingsettings\\edit.6722'),"outlined":"","error-messages":errors[0]},model:{value:(_vm.entity.prepaymentPercent),callback:function ($$v) {_vm.$set(_vm.entity, "prepaymentPercent", $$v)},expression:"entity.prepaymentPercent"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('src\\views\\bookingsettings\\edit.1180'),"outlined":"","error-messages":errors[0]},model:{value:(_vm.entity.prepaymentDays),callback:function ($$v) {_vm.$set(_vm.entity, "prepaymentDays", $$v)},expression:"entity.prepaymentDays"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('src\\views\\bookingsettings\\edit.8210'),"outlined":"","error-messages":errors[0]},model:{value:(_vm.entity.cancelFullDays),callback:function ($$v) {_vm.$set(_vm.entity, "cancelFullDays", $$v)},expression:"entity.cancelFullDays"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('src\\views\\bookingsettings\\edit.2427'),"outlined":"","error-messages":errors[0]},model:{value:(_vm.entity.cancelHalfDays),callback:function ($$v) {_vm.$set(_vm.entity, "cancelHalfDays", $$v)},expression:"entity.cancelHalfDays"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('src\\views\\bookingsettings\\edit.6694'),"outlined":"","error-messages":errors[0]},model:{value:(_vm.entity.endOfBookingDays),callback:function ($$v) {_vm.$set(_vm.entity, "endOfBookingDays", $$v)},expression:"entity.endOfBookingDays"}})]}}])}),_c('v-row',{staticClass:"mx-0",attrs:{"align":"center","justify":"space-between"}},[_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('src\\views\\bookingsettings\\edit.8387')))]),(_vm.entity._id)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.remove}},[_vm._v(_vm._s(_vm.$t('src\\views\\bookingsettings\\edit.2338')))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }